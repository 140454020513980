import { useContext } from "react";
import styled from "styled-components";
import Anchor from "~/components/Anchor";
import ButtonArrow from "~/components/ButtonArrow";
import LinkButton from "~/components/LinkButton";
import MediaFull from "~/components/MediaFull";
import { Desktop, Mobile } from "~/components/ShowcaseB";
import Spacer from "~/components/Spacer";
import { Text } from "~/components/Text";
import Title from "~/components/Title";
import { ThemeContext } from "~/lib/ThemeContext";
import { paragraph } from "~/styles/mixins";
import theme, { smallerThan } from "~/styles/theme";

const TextWrapper = styled.div``;

const TextContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 1fr;
  color: ${(p) => p.color || p.theme.colors.dark};
  /* more space on smaller screeen */
  ${(p) => smallerThan(p.theme.breakpoints.smallDesktop)} {
    grid-template-columns: 3fr 2fr;
  }
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    grid-template-columns: 1fr 2fr;
  }

  ${(p) => smallerThan(p.theme.breakpoints.mobilePortrait)} {
    grid-template-columns: 1fr;
  }
  p {
    ${paragraph};
    margin: ${(p) => p.theme.sizes.vSpacing}rem 0;
    max-width: ${(p) => p.maxWidth || "none"};
  }
`;

function ShowcaseA(props) {
  const currentTheme = useContext(ThemeContext);

  const {
    titleType,
    titleLine1,
    titleLine2,
    titleLine3,
    titleSize,
    titleFontStyle,
    titleFontWeight,
    color = currentTheme.foreground,
    highlightColor = currentTheme.highlight,
    highlightOutline,
    media,
    mediaMobile,
    mediaLazy,
    text,
    fontStyle,
    fontWeight,
    anchorText,
    anchorPreText,
    anchorId,
    buttonText,
    href,
    hrefAs,
    hrefTarget,
    animateIn,
  } = props;

  return (
    <>
      {anchorText && (
        <Anchor id={anchorId} pre={anchorPreText} color={color}>
          {anchorText}
        </Anchor>
      )}

      <Title
        size={titleSize}
        type={titleType}
        line1={titleLine1}
        line2={titleLine2}
        line3={titleLine3}
        titleSize={titleSize}
        fontStyle={titleFontStyle}
        fontWeight={titleFontWeight}
        highlightColor={highlightColor}
        highlightOutline={highlightOutline}
        color={color}
        animateIn={animateIn}
      />
      {media &&
        (!mediaMobile ? (
          <MediaFull
            backgroundColor={theme.colors.greyLighter}
            clickable
            href={href}
            as={hrefAs}
            target={hrefTarget}
            media={media}
            lazy={mediaLazy}
          />
        ) : (
          <>
            <Desktop>
              <MediaFull
                backgroundColor={theme.colors.greyLighter}
                clickable
                href={href}
                as={hrefAs}
                target={hrefTarget}
                media={media}
                lazy={mediaLazy}
              />
            </Desktop>
            <Mobile>
              <MediaFull
                backgroundColor={theme.colors.greyLighter}
                clickable
                href={href}
                as={hrefAs}
                target={hrefTarget}
                media={mediaMobile}
                lazy={mediaLazy}
              />
            </Mobile>
          </>
        ))}
      <TextContainer color={color}>
        <div />
        <div>
          {text && (
            <>
              <Text as="span" fontStyle={fontStyle} fontWeight={fontWeight}>
                {text}
              </Text>
              <Spacer size={4} backgroundColor="transparent" />
            </>
          )}

          {buttonText && href && (
            <LinkButton
              buttonComponent={ButtonArrow}
              buttonProps={{ color }}
              href={href}
              as={hrefAs}
              target={hrefTarget}
            >
              {buttonText}
            </LinkButton>
          )}
        </div>
      </TextContainer>
    </>
  );
}

export default ShowcaseA;
