import Block, { BlockContent } from "~/components/Block";
import ShowcaseA from "~/components/ShowcaseA";
import ShowcaseB from "~/components/ShowcaseB";
import { getCommonSliceProps, getRichText, resolveMedia } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";
import { parseFontStyle, parseFontWeight } from "~/styles/theme";

type PropType = {
  spacingTop?: number;
  spacingBottom?: number;
  backgroundColor?: string;
  highlightColor?: string;
  highlightOutline?: boolean;
  color?: string;
  animateIn?: boolean;

  type?: string;
  align?: string;
  titleType?: string;
  titleLine1?: any;
  titleLine2?: any;
  titleLine3?: any;
  titleSize?: number;
  titleFontStyle?: string;
  titleFontWeight?: number | string;
  media?: any;
  mediaMobile?: any;
  mediaLazy?: boolean;
  text?: any;
  fontStyle?: string;
  fontWeight?: number | string;
  anchorText?: string;
  anchorPreText?: string;
  anchorId?: string;
  buttonText?: string;
  buttonHref?: string;
  buttonAs?: string;
  buttonColor?: string;
  href?: string;
  hrefAs?: string;
  hrefTarget?: string;
};

function ShowcaseASlice(props: PropType) {
  const {
    spacingTop = 0,
    spacingBottom = 6,

    highlightColor,
    highlightOutline,
    color,
    backgroundColor,
    animateIn = false,

    type = "a",
    align = "left",
    titleType,
    titleLine1,
    titleLine2,
    titleLine3,
    titleSize = 1,
    titleFontStyle = "",
    titleFontWeight = "400",
    media,
    mediaMobile,
    mediaLazy = true,
    text,
    fontStyle = "",
    fontWeight = "400",
    anchorText,
    anchorPreText,
    anchorId,
    buttonText,
    href,
    hrefAs,
    hrefTarget,
  } = props;

  // let ShowCaseComponent = ShowcaseA;
  // let align = 'left';
  // if (type === 'b' || type === 'c') {
  //   ShowCaseComponent = ShowcaseB;
  // }
  // if (type === 'c') {
  //   align = 'right';
  // }

  const ShowCaseComponent = type === "a" ? ShowcaseA : ShowcaseB;

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent>
        <ShowCaseComponent
          titleType={titleType}
          titleLine1={titleLine1}
          titleLine2={titleLine2}
          titleLine3={titleLine3}
          titleSize={titleSize}
          titleFontStyle={titleFontStyle}
          titleFontWeight={titleFontWeight}
          color={color}
          highlightColor={highlightColor}
          highlightOutline={highlightOutline}
          media={media}
          mediaMobile={mediaMobile}
          mediaLazy={mediaLazy}
          text={text}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          anchorText={anchorText}
          anchorPreText={anchorPreText}
          anchorId={anchorId}
          buttonText={buttonText}
          href={href}
          hrefAs={hrefAs}
          hrefTarget={hrefTarget}
          align={align}
          animateIn={animateIn}
        />
      </BlockContent>
    </Block>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  // console.log(primary.video.url);

  // video as precedence over image
  // check if primary.video has an url...
  // const media = primary.video.url
  //   ? getMediaArrayFromVideo(
  //       primary.video,
  //       (primary.video_aspect_ratio = '16:9')
  //     )
  //   : getMediaArrayFromImage(primary.image.xlarge);

  let type = "a";
  let align = "left";
  if (primary.type === "Image to the right") {
    type = "b";
  }
  if (primary.type === "Image to the left") {
    type = "b";
    align = "right";
  }

  // console.log('✅', type, align);

  // console.log('primary.link', primary.link);

  // if (primary.link) {
  //   const [href, hrefAs] = resolveLink(primary.link);
  //   console.log(href, hrefAs);
  // }

  const props = {
    ...getCommonSliceProps(slice),
    type,
    align,

    titleType: primary.title_type,
    titleLine1: getRichText(primary.title_line_1),
    titleLine2: getRichText(primary.title_line_2),
    titleLine3: getRichText(primary.title_line_3),
    titleFontStyle: parseFontStyle(primary.title_font_style, "Normal"),
    titleFontWeight: parseFontWeight(primary.title_font_weight, "Normal"),
    media: resolveMedia(primary, "16:9"),
    mediaMobile: resolveMedia(primary, "4:3", "large", true),
    text: getRichText(primary.text),
    fontStyle: parseFontStyle(primary.font_style, "Normal"),
    fontWeight: parseFontWeight(primary.font_weight, "Normal"),
    buttonText: primary.button_text,
    // buttonHref: primary.button_link_href,
    // buttonAs: primary.button_link_as,
    // buttonColor: primary.button_color
    // buttonColor: primary.text_color
  };
  // console.log('PRAOPS', props);
  return props;
}

export default connectSlice(mapSliceToProps)(ShowcaseASlice);
